.DropdownContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    border-radius: 4px;
    height: 30px;
    margin-bottom: 10px;
}

.DropdownOption {
    height: 30px;
    display: flex;
    padding: 5px;
    cursor: pointer;
    border-radius: 4px;
}


.DropdownTitle {
    font-size: 16px;
    width: 90%;
    text-align: left;
}