.OptionsContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-left: 50px;
}

.Option {
    height: 30px;
    display: flex;
    padding: 5px;
    cursor: pointer;
    border-radius: 4px;
}

.OptionCircle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.OptionsTitle {
    font-size: 16px;
    font-weight: bold;
    width: 90%;
    text-align: left;
    text-indent: 10px;
}

.InnerCircle {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: transparent;
}