/* light-theme.css */

:root {
    /* light mode */
    --background-color-1: white;
    --text-color-1: #323338;
    --background-color-2: #dcdfec;
    --text-color-2: #323338;
    --background-color-3: rgb(246, 247, 251);
    --text-color-3: #323338;
    --primary-color-hover: #c8cad6;
    --primary-color-active: #c8cad6;
    --border-color: #e6e9ef;
    --theme-color-orange: orange;
    --theme-color-orange-hover: rgb(224, 146, 0);
    --theme-color-orange-active: rgb(255, 173, 22);
    --background-color-4: rgb(242, 243, 247);

    /* dark mode */
    --background-color-dark-1: #282c34;
    --text-color-dark-1: rgb(231, 231, 231);
    --background-color-dark-2: #1a1a1a;
    --text-color-dark-2: rgb(230, 230, 230);
    --background-color-dark-3: #22252c; /* #c3c6d4 */
    --text-color-dark-3: white;
    --primary-color-hover-dark: #333;
    --primary-color-active-dark: #333;
    --shadow-color-dark: rgb(29, 29, 29);
    --border-color-dark: #333;
    --border-color-dark-light: rgb(50,51,56);
    --background-color-dark-4: #24272e;
}

/* light mode */
.light-background-layer1 {
    background-color: var(--background-color-1);
    color: var(--text-color-1);
}

.light-background-layer2 {
    background-color: var(--background-color-2);
    color: var(--text-color-2);
}

.light-background-layer3 {
    background-color: var(--background-color-3);
    color: var(--text-color-3);
}

.light-background-layer1-gradient {
    background: rgb(242,152,5);
    background: linear-gradient(149deg, rgba(242,152,5,1) 0%, rgba(246,186,88,1) 0%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 76%);
}

.light-primary-color-hover:hover {
    background-color: var(--primary-color-hover);
}

.light-primary-color-active:active {
    background-color: var(--primary-color-active);
}

.light-primary-color-hover-border:hover {
    box-shadow: 0px 3px 12px var(--border-color);
    border: 1px solid transparent;
}


.light-primary-color-active-border:active {
    box-shadow: 0px 3px 12px var(--border-color);
}

.light-primary-color-hover-background {
    background-color: var(--primary-color-hover);
}

.light-border{
    border: 1px solid var(--border-color);
}

.light-border-background {
    background-color: var(--background-color-3);
}

.light-box-shadow {
    box-shadow: 0px 3px 12px var(--border-color);
}

.light-theme-color-orange {
    background-color: var(--theme-color-orange);
}

.light-theme-color-orange-hover:hover {
    background-color: var(--theme-color-orange-hover);
}

.light-theme-color-orange-active:active {
    background-color: var(--theme-color-orange-active);
}

.light-selected {
    background-color: var(--background-color-3);
}

.light-color1-hover:hover {
    background-color: var(--background-color-3);
}

.light-color1-active:active {
    background-color: var(--background-color-2);
}

.light-table-body tr:nth-child(odd) {
    background-color: var(--background-color-4);
}

.light-table-body tr:nth-child(even) {
    background-color: var(--background-color-1);
}

.light-background-color-layer4 {
    background-color: var(--primary-color-active);
}

.light-triangle {
    border-color: transparent transparent var(--background-color-2) transparent;
}

.light-background-divider {
    background-color: var(--text-color-2);
}

/* dark mode */
.dark-background-layer1 {
    background-color: var(--background-color-dark-1);
    color: var(--text-color-dark-1);
}

.dark-background-layer2 {
    background-color: var(--background-color-dark-2);
    color: var(--text-color-dark-2);
}

.dark-background-layer3 {
    background-color: var(--background-color-dark-3);
    color: var(--text-color-dark-3);
}

.dark-background-layer1-gradient {
    background: rgb(246,186,88);
    background: linear-gradient(149deg, rgba(246,186,88,1) 0%, rgba(40,44,52,1) 51%, rgba(40,44,52,1) 100%);
}

.dark-primary-color-hover:hover {
    background-color: var(--primary-color-hover-dark);
}

.dark-primary-color-active:active {
    background-color: var(--primary-color-active-dark);
}

.dark-primary-color-hover-background {
    background-color: var(--primary-color-hover-dark);
}

.dark-primary-color-hover-border:hover {
    box-shadow: 0px 3px 12px var(--shadow-color-dark);
    border: 1px solid transparent;
}


.dark-primary-color-active-border:active {
    box-shadow: 0px 3px 12px var(--shadow-color-dark);
}

.dark-border{
    border: 1px solid var(--border-color-dark);
}

.dark-border-background {
    background-color: var(--border-color-dark);
}

.dark-box-shadow {
    box-shadow: 0px 3px 12px var(--shadow-color-dark);
}

.dark-image {
    filter: invert(1);
}

.dark-theme-color-orange {
    background-color: var(--theme-color-orange);
}

.dark-border-light {
    border: 1px solid var(--border-color-dark-light);
}

.dark-selected {
    background-color: var(--background-color-dark-3);
}

.dark-color1-hover:hover {
    background-color: var(--background-color-dark-3);
}

.dark-color1-active:active {
    background-color: var(--background-color-dark-2);
}

.dark-background-color-border {
    background-color: var(--border-color-dark-light);
}

.dark-table-body tr:nth-child(odd) {
    background-color: var(--);
}

.dark-table-body tr:nth-child(even) {
    background-color: var(--background-color-dark-1);
}

.dark-background-color-layer4 {
    background-color: var(--background-color-4);
}

.dark-triangle {
    border-color: transparent transparent var(--background-color-dark-2) transparent;
}

.dark-background-divider {
    background-color: var(--text-color-dark-2);
}