.FeatureContainer {
    width: 85%;
    margin-left: 15%;
    margin-top: 45px;
    display: grid;
    padding-bottom: 200px;
    transition: all 0.2s ease-in-out;
}

.FeatureTitle {
    font-family: "Poppins",sans-serif;
    font-size: 40px;
    font-weight: 600;
    width: 100%;
    text-align: left;
    margin-left: 30px;
}

