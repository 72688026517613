@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.MainSection {
    grid-gap: 10px;
    width: 100%;
    margin-top: 45px;
}

.MainSectionLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MainSectionLeftItem {
    margin: 10px;
    font-family: "Poppins",sans-serif;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.MainTitle {
    font-family: "Poppins",sans-serif;
    font-size: 40px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    padding-top: 60px;
}

.laptopContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.laptopImage {
    width: 400px;
}

.laptopImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    border-radius: 50%;
}

.MainSubtitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
}

.MainSubtitle {
    font-family: "Poppins",sans-serif;
    font-size: 30px;
    font-weight: 600;
    display: flex;
    text-align: center;
    margin: 10px;
}

.FeaturesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    flex-direction: column;
}

.FeatureDescriptionTitle {
    font-family: "Poppins",sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.FeatureDescription {
    font-family: "Poppins",sans-serif;
    font-size: 16px;
    font-weight: 500;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 20px;
}

.FeatureImage {
    width: 1400px;
    min-height: 392px;
    border-radius: 8px;
    border: 10px solid var(--background-color-2);
    margin-left: 40px;
}