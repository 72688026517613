.TrySection {
    padding: 50px 0;
    text-align: center;
}

.TrySectionTitle {
    font-family: "Poppins",sans-serif;
    font-size: 40px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    padding-top: 60px;
}

.DownloadContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 60px;
}
