.TableTitle {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    width: 90%;
    box-shadow: none;
}

.TableWidgetContainer {
    display: grid;
    grid-template-rows: auto auto 1fr;
    border-radius: 8px;
    width: 90%;
    margin: 50px;
    margin-top: 10px;
}

.TableHeaderContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.TableEntriesContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
}

.TableEntry {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.TableValue {
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TableHeader {
    flex: 1;
    padding: 5px;
    max-width: 100%;
    font-size: 17px;
    font-weight: 600;
}

.RoundedBottom {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.TableTopbar {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 90%;
    background-color: transparent;
    margin-top: 40px;
    justify-content: space-between;
}


.NoDataTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
}