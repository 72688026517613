.WorkspaceContainer{
    display: flex;
    flex-direction: row;
    width: 90%;
    flex-wrap: wrap;
    align-items: center;
    margin: 50px;
}

.InstanceImage {
    width: 80%;
    height: 80%;
    border: none;
}

.InstanceHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin: 20px;
    border-collapse: separate;
    border-spacing: 0;
    cursor: pointer;
    width: 300px;
    height: 280px;
    flex-grow: 0;
    flex-shrink: 0;
}