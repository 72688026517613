.InfoDisplayContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    position: relative;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 99999;
    border-radius: 8px;
    font-size: 13px;
}

.TriangleBefore {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 10px 10px 10px;
    border-style: solid;
  }