.HierarchyItem {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    text-decoration: none;
    border: none;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 4px;
    font-size: 15px;
    z-index: 1;
}

.HierarchyIcon {
    height: 12px;
    margin-left: 5px;
    margin-right: 5px;
}

.ShowChildren {
    position: absolute;
    left: 40px;
    font-weight: bold;
    transition: rotate 0.2s ease-in-out;
    font-size: 14px;
}

.ShowChildrenEnabled {
    rotate: 90deg;
}