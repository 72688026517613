.SubFeature {
    width: 100%;
    font-family: "Poppins",sans-serif;
}

.SubFeatureTitle {
    font-size: 30px;
    text-decoration: underline;
    margin-left: 60px;
    font-weight: 400;
}

.SubFeatureDescription {
    font-size: 18px;
    font-weight: 300;
    padding-bottom: 20px;
    margin-left: 80px;
    width: 90%;
}

@media (max-width: 1800px) {
    .SubFeatureDescription {
        margin-left: 10%;
    }
    .SubFeatureTitle {
        margin-left: 5%;
    }
}

.SubFeatureImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
}

.SubFeatureImage {
    max-width: 40%;
}
