.LeftContainer {
    width: 250px;
    border-radius: 8px;
    height: 100vh;
}

.AddButton {
    width: 30px;
    height: 30px;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    background: none;
    border: none;

}

.TopButtonContainer {
    width: 210px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin: 20px;
}

.SearchTextInput {
    border-radius: 4px;
    font-size: 15px;
    flex-grow: 1;
    width: 30px;
    padding: 0;
    text-indent: 10px;
}