.ListSelectorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 400px;
    scroll-behavior: auto;
    gap: 5px;
}

.SelectionItemContainer {
    width: 80%;
    text-align: center;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}