.AddMenuContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    position: relative;
    top: 0;
    padding: 20px;
    border-radius: 8px;
    z-index: 2;
}

.AddMenuButton {
    width: 100%;
    height: 20px;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    border-radius: 4px;
    cursor: pointer;
    background: none;
    border: none;
    text-indent: 10px;
    transition: all 0.2s ease-in-out;
}

.show-addMenu {
    top: 10px;
    transition: all 0.2s ease-in-out;
}

.MenuButtonImage {
    height: 12px;
    margin-right: 10px;
}