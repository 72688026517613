.DownloadButton {
    color: black;
    border-radius: 10px;
    font-family: "Poppins",sans-serif;
    background-color: orange;
    font-weight: 500;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    min-width: 126px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.DownloadButton:hover {
    cursor: pointer;
    background-color: #ff8c1a;
}

.DownloadButton:active {
    background-color: #ff9933;
}

.DownloadImage {
    width: 20px;
}