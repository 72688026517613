.InfoContainer{
    display: flex;
    flex-direction: row;
    width: 90%;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 50px;
    flex-grow: 1;
}

.InfoItemContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    flex-grow: 1;
    gap: 10px;
}

.InfoKey {
    font-size: 15px;
    font-weight: 600;
    margin-top: 2px;
    margin-bottom: 2px;
}

.InfoValue {
    font-size: 15px;
    font-weight: 300;
    margin-top: 2px;
    margin-bottom: 2px;
}

.InfoTitle {
    font-size: 20px;
    font-weight: 600;
}

.Divider {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.InfoDivider {
    width: 100%;
    height: 1px;
    margin-bottom: 10px;
}

.SubTitle {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}

