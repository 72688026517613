.TableProfile {
    width: 40px;
    height: 40px;
    background-color: var(--theme-color-orange);
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    border-radius: 50%;
    color: black;
    cursor: pointer;
}