@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.Topbar {
    position: fixed;
    width: 100%;
}

.MainTopbar {
    /* background-color: #1a1a1a; */
    height: 45px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
    /* box-shadow: 0 2px 5px rgba(0,0,0,0.5); */
}

.logo {
    height: 80%;
    width: auto;
}

.TopbarLeftItem {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.TopbarLeft {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}

.TopbarRight {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    font-weight: 400;
    font-family: "Poppins",sans-serif;
}

.TopbarRightItem {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 200;
    font-family: "Poppins",sans-serif;
    cursor: pointer;
    text-decoration: none;
    border-radius: 10px;
}

.topbarItemIcon {
    height: 20px;
    width: 20px;
    margin: auto;
}

.profileIcon {
    height: 29px;
    width: 29px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.imageIcon {
    height: 95%;
    width: 95%;
    border-radius: 50%;
}

.LoginButton {
    background-color: orange;
    color: black;
    margin-left: 5px;
}

.LoginButton:hover {
    cursor: pointer;
    background-color: #ff8c1a;
}

.LoginButton:active {
    background-color: #ff9933;
}

.HamburgerTopbar {
    height: 30px;
    width: 30px;
    margin: auto;
}

.HamburgerButton {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
}

.HamburgerMenu {
    /* width: 100%; */
    /* display: flex; */
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-family: "Poppins",sans-serif;;
    flex-direction: column;
    display: flex;
} 

.HamburgerMenuItem {
    height: 40px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 200;
    font-family: "Poppins",sans-serif;
    cursor: pointer;
    text-decoration: none;
    border-radius: 10px;
}

.HamburgerMenuGroup {
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}  

.logoText {
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins",sans-serif;
    margin-left: 10px;
    text-decoration: none;
    border: none;
}

a:link { 
text-decoration: none; 
} 
a:visited { 
text-decoration: none; 
} 
a:hover { 
text-decoration: none; 
} 
a:active { 
text-decoration: none; 
}