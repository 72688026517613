.HexShapeMiddle {

    float: left;
    width: 60px;
    height: 104px;

    cursor: pointer;

    font-size: 12px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    z-index: 6;
    position: relative;
}

.HexShape1Dark {
    float: left;
    border-right: 30px solid var(--background-color-dark-2);
    border-top: 52px solid transparent;
    border-bottom: 52px solid transparent;
    z-index: 5;
    position: relative;
}

.HexShape2Dark {
    float: left;
    border-left: 30px solid var(--background-color-dark-2);
    border-top: 52px solid transparent;
    border-bottom: 52px solid transparent;
    z-index: 5;
    position: relative;
}

.HexShape1Light {
    float: left;
    border-right: 30px solid var(--background-color-2);
    border-top: 52px solid transparent;
    border-bottom: 52px solid transparent;
    z-index: 5;
    position: relative;
}

.HexShape2Light {
    float: left;
    border-left: 30px solid var(--background-color-2);
    border-top: 52px solid transparent;
    border-bottom: 52px solid transparent;
    z-index: 5;
    position: relative;
}

.HexOption {
    background-color: transparent;
    cursor: pointer;
    border-radius: 50%;
    padding: 10px;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.HexOption:hover {
    scale: 1.1;
}

.HexOptionSelected {
    scale: 1.1;
}

.HexContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    flex-direction: column;
}

.HexOptionImage {
    width: 30px;
}

.HexOptionsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.HexOptionsTitle {
    font-size: 20px;
    font-weight: 500;
    font-family: "Poppins",sans-serif;
    text-align: center;
}