.HomeContainer {
    height: 100vh;
    flex-grow: 1;
    border-radius: 8px 0 0 8px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HomeTabs {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px;
    border-radius: 8px;
}