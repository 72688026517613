@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

* {
  scroll-behavior: smooth;
  font-family: "Poppins",sans-serif;
}

.MainGrid {
  overflow: hidden;
  min-height: 100vh;
}

.BlurBackground {
  height: 120vh;
  width: 100%;
  position: absolute;
  z-index: -1;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  visibility: hidden;
  margin: auto;
  padding: 0;
  background-color: gray;
  opacity: 0.6;
}

.BlurBackgroundEnabled {
  visibility: visible;
  z-index: 9998;
}
