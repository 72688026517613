.LoginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    gap: 100px;
}

.LoginImage {
    width: 400px;
}

.LoginTitle {
    font-size: 40px;
    text-align: center;
    margin-top: 70px;
}

.SignInTitle {
    font-size: 30px;
    text-align: center;
}

.LoginBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 80px;
    min-width: 400px;
    border-radius: 8px;
}

.SignInContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.SignInDivider {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.DividerLine {
    flex-grow: 1;
    height: 1px;
}

.SubDetails {
    font-size: 12px;
}

.loginlink {
    font-size: 12px;
    color: rgb(53, 53, 201);
    text-decoration: none;
    text-decoration: underline;
}

.LoginError {
    color: red;
    font-size: 12px;
}