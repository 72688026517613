.PlanSection {
    text-align: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;
}

.PlanSectionTitle {
    font-family: "Poppins",sans-serif;
    font-size: 40px;
    font-weight: 600;
    width: 100vw;
    text-align: center;
    padding-bottom: 20px;

}

.PlansContainer {
    width: 50%;
    margin: auto;
}

.PlanTitle {
    font-family: "Poppins",sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

.PlansTable {
    width: 60%;
    border-collapse: collapse;
    margin: auto;
    font-family: "Poppins",sans-serif;
    padding: 0;
  }
  
  .PlansTable th, td {
    text-align: center;
    padding: 10px;
  }
  
  .PlansTable th {
    font-weight: 400;
    font-size: 20px;
  }
  
  .PlansTable tr:nth-child(even) {
    background-color: orange;
    color: black;
  }

  .ChooseButtonSection {
    padding: 20px;
    min-width: 126px;
  }

  .PlanSectionPlans {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 40px;
    flex-wrap: wrap;
  }

  .PlanSectionPlan {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;
  }

  .PlanSectionPlanTitle {
    font-family: "Poppins",sans-serif;
    font-size: 27px;
    font-weight: 600;
    padding: 10px;
  }

  .PlanSectionPlanPrice {
    font-family: "Poppins",sans-serif;
    font-size: 30px;
    font-weight: 600;
    padding: 5px;
  }

  .PlanSectionPriceGroup {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .PlanSectionDivider {
    width: 100%;
    height: 1px;
    margin: 10px;
    margin-left: -20px;
    padding-left: 40px;
  }

  .PlanSectionPlanFeatures {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 10px;
  }

  .PlanSectionPlanFeature {
    font-family: "Poppins",sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    min-width: 210px;
    text-align: left;
  }