.invoice-container{
    font-size: 10px;
    margin-bottom: 10px;
    text-align: center;
    padding: 30px;
    width: 500px;
    z-index: 9999;
    background-color: white;
    color: black;
    width: 620px;
    height: 877px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    color: #3498db;
  }
  
  /* Invoice Details Styles */
  .invoice-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .from, .to {
    flex-basis: 48%;
  }
  
  /* Table Styles */
  .invoice-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .invoice-cell {
    border: 1px solid black;
    padding: 10px;
    text-align: left;
  }
  
  .invoice-container th {
    background-color: #b3b3b3;
  }
  
  /* Total Styles */
  .invoice-total {
    font-weight: bold;
  }

  .invoice-box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 1px solid black;
    padding: 10px;
    text-align: left;
  }

  .invoice-box-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }

  .invoice-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-grow: 1;
  }

  .details-left, .details-right {
    text-align: left;
  }

  .invoice-title {
    text-align: left;
    padding: 10px;
  }