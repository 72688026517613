.ContactParagraph {
    font-family: "Poppins",sans-serif;
    font-size: 13px;
    font-weight: 300;
    width: 30%;
    text-align: center;
}

.ContactContainer {
    height: 270px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
}