.LeftLinkContainer {
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    width: 15%;
    height: 100vh;
    font-family: "Poppins",sans-serif;
    font-size: 14px;
    top: 45px;
}

.LeftLinkContainerStop {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    width: 15%;
    height: 100vh;
    font-family: "Poppins",sans-serif;
    font-size: 14px;
    text-align: center;
    top: 45px;
}

.LeftLinkContainer:hover {
    width: 30%;
    /* transition: all 0.2s ease-in-out; */
}

.LeftLinkContainerStop:hover {
    width: 30%;
    /* transition: all 0.2s ease-in-out; */
}

@media (max-width: 1800px) {
    .LeftLinkContainer {
        width: 30%;
    }
    .LeftLinkContainerStop {
        width: 30%;
    }
}


.HoverTransition {
    transition: all 0.2s ease-in-out;
}

.LeftLinkTitle {
    font-family: "Poppins",sans-serif;
    font-size: 24px;
    font-weight: 300;
    width: 100%;
    text-align: center;
    padding-top: 30px;
}

.LeftLinkItem {
    font-family: "Poppins",sans-serif;
    font-size: 18px;
    font-weight: 300;
    width: 100%;
    text-align: left;
    padding-bottom: 5px;
    padding-top: 5px;
    text-decoration: none;
    text-indent: 5px;
    cursor: pointer;
    background: none;
	border: none;
	cursor: pointer;
	outline: inherit;
}


.LeftLinkSubItem {
    font-family: "Poppins",sans-serif;
    font-size: 14px;
    font-weight: 300;
    width: 100%;
    text-align: left;
    text-decoration: none;
    text-indent: 20px;
}

.CloseLeft {
    width: 100%;
    height: 50px;
    font-family: "Poppins",sans-serif;
    font-size: 50px;
    line-height: 50px;
    font-weight: 400;
    background: none;
	border: none;
    outline: inherit;
    text-align: right;
    margin-right: 20px;
    position: absolute;
    bottom: 50px;
}

.CloseLeftContainer {
    width: min-content;
    height: min-content;
    font-family: "Poppins",sans-serif;
    font-size: 50px;
    line-height: 50px;
    font-weight: 400;
    background: none;
	border: none;
    outline: inherit;
    text-align: center;
    margin-right: 20px;
    right: 10px;
    cursor: pointer;
}

/* .CloseLeftContainer:hover {

} */


@media (max-width: 1800px) {
    .LeftLinkContainer {
        width: 30%;
    }
    .LeftLinkContainerStop {
       width: 30%;
    }
}