.DownloadButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 200px;
    padding-top: 20px;
}

.DownloadSubtitleContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.DownloadSubtitle {
    font-family: "Poppins",sans-serif;
    font-size: 13px;
    font-weight: 300;
    width: 30%;
    text-align: center;
    color: white;
}