.TabTopbar {
    width: 100%;
    border-radius: 8px 8px 0 0;
    padding-top: 20px;
    padding-bottom: 20px;
    text-indent: 40px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.TabContainer {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TabButton {
    font-size: 14px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    flex: 1 1 0px;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 10px;
}

.AddTab {
    left: 99%;
    font-size: 16px;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background-color: orange;
    border: none;
    border-radius: 50%;
    height: 23px;
    width: 23px;
    padding: 0;
    visibility: hidden;
}

.TabTopbar:hover .AddTab {
    visibility: visible;
}